import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyAFI_Y4tnAdmQnbYCTg_dO81FXlKJdZ_Mc",
  authDomain: "tlartdesigns.firebaseapp.com",
  databaseURL: "https://tlartdesigns-default-rtdb.firebaseio.com",
  projectId: "tlartdesigns",
  storageBucket: "tlartdesigns.appspot.com",
  messagingSenderId: "348692757945",
  appId: "1:348692757945:web:8172bf998200e741008a68",
  measurementId: "G-PGVWJ0XEJT"
};
// Initialize Firebase
try {
  firebase.initializeApp(firebaseConfig);
} catch (err) {
  // we skip the "already exists" message which is
  // not an actual error when we're hot-reloading
  if (!/already exists/.test(err.message)) {
    //console.error("Firebase initialization error", err.stack);
  }
}
// Get a reference to the database service
var database = firebase.database();
export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();
export default firebase;
